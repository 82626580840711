const livestream = 'https://klrc.streamguys1.com/klrc-aac';
const audio = new Audio();
audio.volume = 0.8;

function updateUrlParameter(uri, key, value) {
    // remove the hash part before operating on the uri
    var i = uri.indexOf('#');
    var hash = i === -1 ? ''  : uri.substr(i);
    uri = i === -1 ? uri : uri.substr(0, i);
  
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
  
    if (!value) {
      // remove key-value pair if value is empty
      uri = uri.replace(new RegExp("([?&]?)" + key + "=[^&]*", "i"), '');
      if (uri.slice(-1) === '?') {
        uri = uri.slice(0, -1);
      }
      // replace first occurrence of & by ? if no ? is present
      if (uri.indexOf('?') === -1) uri = uri.replace(/&/, '?');
    } else if (uri.match(re)) {
      uri = uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
      uri = uri + separator + key + "=" + value;
    }
    return uri + hash;
}
  
// Fetch the current song playing
setInterval(function() {
    fetch('/media/get_now_playing').then(response => response.json()).then(data => {
        let songid = $('.current-stream').data('songid');
        if (data.songID != songid) {
            $('.current-stream').replaceWith(data.content);
            $('.current-stream').data('songid', data.songID);
            $('.btn-vote').removeClass('disabled');

            $('#media-player .recently-played-list').prepend(data.listitem);
        }
    });
}, 15000);

function loadPodcastEpisode(episodeID) {
    fetch(`/media/get_podcast_episode/${episodeID}`).then(response => response.json()).then(data => {
        $('.player-main-podcast').replaceWith(data.fullplayer)
        $('.current-podcast').replaceWith(data.miniplayer);
        $('.podcast-current-show').replaceWith(data.currentshow);
    });

}

// Get Donations
let donationSlider = $('.donation-slider');
let donationStart = donationSlider.data('start');
setInterval(function() {
    fetch(`/ajax/donations?start=${donationStart}`).then(response => response.json()).then(data => {
        if (data.donations == '') { return; }
        let donations = $.parseHTML(data.donations);
        donationStart = data.start;
        $.each(donations, function(i, donation) {
            donationSlider.slick('slickAdd', donation);
        });
    });
}, 30000);

// Get Fundraiser Update
setInterval(function() {
    fetch(`/ajax/fundraiser`).then(response => response.json()).then(data => {
        if (data.content == '') { return; }
        $('.fundraiser-wrap').replaceWith(data.content);
    });
}, 30000);


if ($('.app-wrap').hasClass('has-alert')) {
    setTimeout(function() {
        $('.app-wrap').addClass('show-alert');
    }, 1500);
}
if ($('.app-wrap').hasClass('has-mobile-alert')) {
    setTimeout(function() {
        $('.app-wrap').addClass('show-mobile-alert');
    }, 1500);
}

// Play the audio stream
$(document).on('click', '.btn-play-audio', function() {
    if (audio.src == window.location.href || audio.src == '') {audio.src = livestream;}
    audio.play();
    $('#media-player').addClass('playing');
});
$(document).on('click', '#btn-play-radio', function() {
    audio.src = livestream;
    audio.play();
    $('#media-player').removeClass('podcast-playing').addClass('playing');
});

$(document).on('click', '.btn-pause-audio', function() {
    // Hack for pausing the livestream to mimic a "stop"
    if (audio.src == livestream) {
        audio.pause();
        audio.src = '';    
    } else {
        audio.pause();
    
    }
    $('#media-player').removeClass('playing');
});

// Vote for a song
$(document).on('click', '.btn-vote-up:not(.disabled)', function() {
    let hash = $('.current-song').data('hash');
    let voteTooltip = $(this).parent().find('.vote-tooltip');
    if ($('#media-player').hasClass('expanded')) {
        voteTooltip.css('transform', 'translateX(-100px)');
    } else {
        voteTooltip.css('transform', 'translateX(-50px)');
    }
    voteTooltip.fadeIn();
    
    fetch(`/media/voteup/${hash}`).then(response => response.json()).then(data => {
        $(this).addClass('disabled');
    });
});
$(document).on('click', '.btn-vote-down:not(.disabled)', function() {
    let hash = $('.current-song').data('hash');
    let voteTooltip = $(this).parent().find('.vote-tooltip');
    // Position the tooltip above the button
    if ($('#media-player').hasClass('expanded')) {
        voteTooltip.css('transform', 'translateX(100px)');
    } else {
        voteTooltip.css('transform', 'translateX(50px)');
    }
    voteTooltip.fadeIn();
    fetch(`/media/votedown/${hash}`).then(response => response.json()).then(data => {
        $(this).addClass('disabled');
    });
});

$(document).on('click', '.btn-close-tooltip', function() {
    $('.vote-tooltip').fadeOut();
    return false;
});


// .podcast-tile:not(.active), 
// Play a podcast episode on demand
$(document).on('click', '.btn-play-episode', function(e) {
    let episodeID = $(this).data('episode');
    if (episodeID !== undefined) {
        let url = $(this).data('url');
        audio.src = url;
        audio.play();
        $('#media-player').addClass('playing podcast-playing');
        $(this).parents('.podcast-episode-element').addClass('playing');
        loadPodcastEpisode(episodeID);
    }
    e.preventDefault();
    e.stopPropagation();
    return false;
});
$(document).on('click', '#media-player .podcast-tile:not(.active)', function(e) {
    let episodeID = $(this).data('episode');
    if (episodeID !== undefined) {
        let url = $(this).data('url');
        audio.src = url;
        audio.play();
        $('#media-player').addClass('playing podcast-playing');
        $(this).parents('.podcast-episode-element').addClass('playing');
        loadPodcastEpisode(episodeID);
    }
    return false;
});

$(document).on('click', '.btn-pause-episode', function(e) {
    audio.pause();
    $('#media-player').removeClass('playing');
    $(this).parents('.podcast-episode-element').removeClass('playing');
    e.preventDefault();
    e.stopPropagation();
    return false;
});

$(document).on('click', '.btn-jump-forward', function() {
    audio.currentTime += 30;
});
$(document).on('click', '.btn-jump-backward', function() {
    audio.currentTime -= 10;
});

$(document).on('click', '.btn-skip-forward', function() {
    let nextEpisode = $('#podcast-episode-list').find('.active').next();
    if (nextEpisode.length) {
        nextEpisode.click();
    }
});
$(document).on('click', '.btn-skip-backward', function() {
    let prevEpisode = $('#podcast-episode-list').find('.active').prev();
    if (prevEpisode.length) {
        prevEpisode.click();
    } else {
        prevEpisode = $('#podcast-episode-list').find('.podcast-tile').last();
    }
    prevEpisode.click();
});

$(document).on('click', '#btn-load-more-episodes:not(.loading)', function() {
    let podcastID = $(this).data('podcast');
    let page = $(this).data('page');
    $(this).addClass('loading');
    fetch(`/media/get_podcast_episodes/${podcastID}/${page}`).then(response => response.json()).then(data => {
        $('#podcast-episode-list').append(data.content);
        $('#btn-load-more-episodes').data('page', page + 1).removeClass('loading').appendTo('#podcast-episode-list');
    });
});

$(document).on('click', '.btn-expand-player', function() {
    $('#media-player').toggleClass('expanded');
});
$(document).on('click', '.btn-minimize-player', function() {
    $('#media-player').removeClass('expanded');
});

$(document).on('input change', '#media-volume-slider', function() {
    let level = $(this).val();
    audio.volume = level / 100;
    // Adjust the volume icon when muted
    if (level == 0) {
        $('#btn-volume').addClass('muted');
    } else {
        $('#btn-volume').removeClass('muted');
    }
});
// Mute the audio
$(document).on('click', '#btn-volume', function() {
    if (audio.volume == 0) {
        audio.volume = 0.8;
        $('#media-volume-slider').val(80);
    } else {
        audio.volume = 0;
        $('#media-volume-slider').val(0);
    }
    $(this).toggleClass('muted');
});

// When a podcast episode is played, update the progress bar
audio.addEventListener('timeupdate', function() {
    let position = audio.currentTime / audio.duration * 100;
    $('.podcast-scrub-slider').val(position);
    let currentTime = new Date(null);
    currentTime.setSeconds(audio.currentTime);
    $('.current-time').text(currentTime.toISOString().substr(14, 5));
});

// When the progress bar is clicked, jump to that position in the audio
$(document).on('input', '.podcast-scrub-slider', function() {
    let position = $(this).val();
    audio.currentTime = position / 100 * audio.duration;
});


$(document).on('click', '#btn-menu:not(.disabled)', function() {
    $(this).addClass('disabled');
    let flyoutMenu = document.getElementById('flyout-menu');
    flyoutMenu.style.zIndex = 10;
    flyoutMenu.classList.add('open');
    document.querySelector('body').classList.add('menu-open');
});

$(document).on('click', '#btn-close-menu', function() {
    let flyoutMenu = document.getElementById('flyout-menu');
    flyoutMenu.classList.remove('open');
    document.querySelector('body').classList.remove('menu-open');
    setTimeout(function() {
        flyoutMenu.style.zIndex = -1;
        $('#btn-menu').removeClass('disabled');
    }, 1000);
});

// $(document).on('click', '.l1-link.no-link', function(e) {
//     e.preventDefault();
//     return false;
// });

$(document).on('click', '.btn-expand-menu, .l1-link', function(e) {
    $(this).parent().find('.submenu').toggleClass('open');
    e.preventDefault();
    return false;
});

$(document).on('click', '.btn-show-mobile-recents, .btn-mobile-close-recents', function(e) {
    $(this).parents('.mobile-player').find('.main-right').toggleClass('open');
    $(this).parents('.mobile-player').find('.main-left').toggleClass('blur');
});
$(document).on('click', '.btn-show-mobile-episodes, .btn-mobile-close-episodes', function(e) {
    $(this).parents('.mobile-player').find('.main-right').toggleClass('open');
    $(this).parents('.mobile-player').find('.main-left').toggleClass('blur');
});
$(document).on('click', '.btn-show-mobile-shows, .btn-mobile-close-shows', function(e) {
    $(this).parents('.mobile-player').find('.mobile-podcasts-list').toggleClass('open');
    $(this).parents('.mobile-player').find('.main-left').toggleClass('blur');
});

$(document).on('click', '.btn-mobile-switch-radio', function(e) {
    $(this).parent().find('.button').removeClass('active');
    $(this).addClass('active');
    $(this).parents('.mobile-player').find('.player-main-stream').show();
    $(this).parents('.mobile-player').find('.player-main-podcast').hide();
    e.preventDefault();
    return false;
});
$(document).on('click', '.btn-mobile-switch-podcasts', function(e) {
    $(this).parent().find('.button').removeClass('active');
    $(this).addClass('active');
    $(this).parents('.mobile-player').find('.player-main-stream').hide();
    $(this).parents('.mobile-player').find('.player-main-podcast').show();
    e.preventDefault();
    return false;
});


$(document).on('click', '.l2-link, .flyout-link', function() {
    $('#btn-close-menu').click();
});
$(document).on('click', '.close-player-link', function() {
    $('#media-player').toggleClass('expanded');
});



$(document).on('click', '.btn-praise:not(.disabled)', function(e) {
    let blogID = $(this).data('blog');
    let blogType = $(this).data('type');
    $('#response-count-' + blogID).addClass('increase');
    // $('#response-count-' + blogID).text(parseInt(count) + 1);
    $('#icon-reaction-' + blogID).addClass('float');
    $(this).addClass('disabled');
    fetch('/ajax/praise', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            blogID: blogID,
            blogType: blogType
        })
    })
    .then(response => response.json())
    .then(data => {
        console.log(data);
    });
    e.preventDefault();
    e.stopPropagation();
    return false;
});

$(document).on('click', '.btn-view-all-events', function(e) {
    let link = $(this).data('link');
    window.location = link;
    e.stopPropagation();
    return false;
});

$(document).on('click', '.btn-letter-artist', function() {
    let letter = $(this).data('letter');
    $('#load-more-artists').remove();
    $('.btn-letter').removeClass('active');
    $(this).addClass('active');
    $('#artists-list').html('<div class="loader"><i class="fa-light fa-spinner fa-spin"></i></div>');
    fetch(`/ajax/artists/${letter}`).then(response => response.json()).then(data => {
        $('#artists-list').replaceWith(data.content);
        $('input[name="search"]').val('');
    });
});

$(document).on('click', '.btn-letter-song', function() {
    let letter = $(this).data('letter');
    $('#load-more-songs').remove();
    $('.btn-letter').removeClass('active');
    $(this).addClass('active');
    $('#songs-list').html('<div class="loader"><i class="fa-light fa-spinner fa-spin"></i></div>');
    fetch(`/ajax/songs/${letter}`).then(response => response.json()).then(data => {
        $('#songs-list').replaceWith(data.content);
        $('input[name="search"]').val('');
    });
});

$(document).on('click','.btn-expand-signup', function() {
    $(this).parents('.elements__insidersignup').addClass('expanded');
});

$(document).on('click','.btn-submit-signup', function() {
    if (document.getElementById('email-signup-email').value == '') {
        $('#email-signup-message').text('Please enter your email address.').show();
        return;
    }
    fetch('/ajax/signup', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email: document.getElementById('email-signup-email').value,
            firstname: document.getElementById('email-signup-firstname').value,
            lastname: document.getElementById('email-signup-lastname').value,
            phone: document.getElementById('email-signup-phone').value,
        })
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            document.getElementById('email-signup-email').value = '';
            document.getElementById('email-signup-firstname').value = '';
            document.getElementById('email-signup-lastname').value = '';
            document.getElementById('email-signup-phone').value = '';
            document.getElementById('email-signup-message').innerHTML = data.message;
            document.getElementById('email-signup-message').style.display = 'inline-block';
            document.getElementById('email-signup-form').style.display = 'none';
            $('.btn-submit-signup').hide();
        } else {
            document.getElementById('email-signup-message').innerHTML = data.message;
        }
    })
});

$(document).on('click', '.link-minimize-player', function() {
    $('#media-player').removeClass('expanded');
});

$(document).on('change', '.station-select', function() {
    let station = $(this).val();
    window.location = station;
});

$(document).on('change', '.filter-dropdown', function() {
    let value = $(this).val();
    let filter = $(this).data('filter');

    let form = $(this).closest('form');
    form.submit();
    
});
$(document).on('change', '.date-dropdown', function() {
    let value = $(this).val();
    let filter = $(this).data('filter');

    let form = $(this).closest('form');
    form.submit();
    
});

$(document).on('click', '.btn-closing-type', function() {
    let type = $(this).data('type');
    $('.btn-closing-type').removeClass('active');
    $(this).addClass('active');
    let newURL = updateUrlParameter(window.location.href, 'type', type);
    window.location = newURL;
    return false;
});

$(document).on('click', '.btn-close-alert', function(e) {
    $('.app-wrap').removeClass('show-alert');
    $('.app-wrap').removeClass('show-mobile-alert');
    e.preventDefault();
    return false;
});

// Form for submitting hope stories and prayer requests
$(document).on('submit', '.share-form', function() {
    let form = $(this);
    let submitMessage = form.parent().find('.success-message');
    form.addClass('loading disabled');

    $(this).ajaxSubmit({
        success: function(){
            form.removeClass('loading disabled').hide();
            submitMessage.show();
        },
        clearForm:true
    });

    return false;
});

// Form for submitting closings
$(document).on('submit', '.closing-form', function() {
    let form = $(this);
    let submitMessage = form.parent().find('.success-message');
    form.addClass('loading disabled');

    $(this).ajaxSubmit({
        success: function(){
            form.removeClass('loading disabled').hide();
            submitMessage.show();
        },
        clearForm:true
    });

    return false;
});

// Event for submitting events
$(document).on('submit', '.event-form', function() {
    let form = $(this);
    let submitMessage = form.parent().find('.success-message');
    let modalheader = form.parent().find('.modal-header');
    modalheader.hide();
    form.addClass('loading disabled');

    setTimeout(function() {
        form.removeClass('loading disabled');
        submitMessage.hide();
    }, 120000);

    $(this).ajaxSubmit({
        success: function(data){
            let resp = JSON.parse(data);
            if (resp.error) {
                form.removeClass('loading disabled').show();
                submitMessage.html(resp.message);
                submitMessage.show();    
            } else {
                form.removeClass('loading disabled').hide();
                submitMessage.html(resp.message);
                submitMessage.show();
            }
        },
        clearForm:true
    });

    return false;
});

$(document).on('submit', '#birthday-form', function() {
    let form = $(this);
    let submitMessage = form.parent().find('.form-message');
    form.addClass('loading disabled');

    $(this).ajaxSubmit({
        dataType: 'json',
        success: function(data){
            if (data.success) {
                form.removeClass('loading disabled').hide();
            } else {
                form.removeClass('loading disabled').show();
            }
            submitMessage.text(data.message);
            submitMessage.show();
        },
        error: function(data) {
            console.log('error');
            form.removeClass('loading disabled').show();
            submitMessage.text(data.message);
            submitMessage.show();
        },
        clearForm:true
    });

    return false;
});

$(document).on('click', '#btn-event-terms', function() {
    $('#events-disclaimer-screen').hide();
    $('#events-submission-screen').show();
});

$(document).on('click', '#btn-add-event-details', function() {
    let eventDetails = $('.event-details').last();
    let newEventDetails = eventDetails.clone();
    newEventDetails.insertAfter(eventDetails);
});

function initScripts() {

    $(document).foundation();

    // Check for query string parameter for the share modal
    const urlParams = new URLSearchParams(window.location.search);
    const sharePrayer = urlParams.get('share-prayer');
    const shareStory = urlParams.get('share-story');
    const submitEvent = urlParams.get('submit-event');
    if (sharePrayer) {
        $('#share-prayer-modal').foundation('open');
    }
    if (shareStory) {
        $('#share-story-modal').foundation('open');
    }
    if (submitEvent !== null) {
        $('#submit-event-modal').foundation('open');
    }

    if($('#recent-songs-form').length > 0){

        $.each($('#recent-songs-form select'), function(){
            var self = $(this),
                selected = self.data('selected');

            if(selected){
                self.val(selected);
            }
        });

    }

    if (document.getElementById('load-more-songs')) {
        $(document).on('scroll', function() {
            let loadMore = $('#load-more-songs:not(.loading)');
            if (loadMore.length == 0) { return; }
            if (loadMore[0].getBoundingClientRect().top < window.innerHeight) {
                let page = loadMore.data('page');
                loadMore.addClass('loading');
                fetch(`/ajax/songs?page=${page}`).then(response => response.json()).then(data => {
                    if (data.content == '') {
                        loadMore.remove();
                        return;
                    }
                    $('#songs-list').append(data.content);
                    $('#load-more-songs').data('page', parseInt(page) + 1).removeClass('loading');
                });
            }
        });
    }
    if (document.getElementById('load-more-artists')) {
        $(document).on('scroll', function() {
            let loadMore = $('#load-more-artists:not(.loading)');
            if (loadMore.length == 0) { return; }
            if (loadMore[0].getBoundingClientRect().top < window.innerHeight) {
                let page = loadMore.data('page');
                loadMore.addClass('loading');
                fetch(`/ajax/artists?page=${page}`).then(response => response.json()).then(data => {
                    if (data.content == '') {
                        loadMore.remove();
                        return;
                    }
                    $('#artists-list').append(data.content);
                    $('#load-more-artists').data('page', parseInt(page) + 1).removeClass('loading');
                });
            }
        });
    }
    

    if($('.elements__herocarousel').length > 0) {
        $('.elements__herocarousel').not('.slick-initialized').slick({
            dots: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed:7000,
            pauseOnHover: false,
            prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
        });    
    }

    if($('.grid-slider').length > 0) {
        $('.grid-slider').not('.slick-initialized').slick({
            dots: false,
            infinite: true,
            arrows: false,
            autoplay: false,
            slidesToShow: 1,
            mobileFirst: true,
            responsive: [
                {
                breakpoint: 640,
                settings: "unslick"
                }
            ]
        });
    }

    if($('.donation-slider').length > 0) {
        $('.donation-slider').not('.slick-initialized').slick({
            dots: false,
            infinite: true,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 4000,
            pauseOnHover: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });
    }

    $('.blog-slider').each((index, el) => {
        $(el).not('.slick-initialized').slick({
            dots: false,
            infinite: false,
            autoplay: false,
            prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
            appendArrows: $(el).parents('.slider-container-wrap'),
            slidesToShow: 5,
            centerMode: false,
            adaptiveHeight: true,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 4,
                        centerMode: false,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        centerMode: false
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        centerMode: false
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                        centerMode: false,
                        variableWidth: true
                    }
                }
            ]
        });
    });

    if($('.podcast-slider').length > 0) {
        $('.podcast-slider').not('.slick-initialized').slick({
            dots: false,
            infinite: false,
            autoplay: false,
            prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
            slidesToShow: 4,
            centerMode: false,
            adaptiveHeight: true,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        centerMode: false,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        centerMode: false
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        centerMode: false
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                        centerMode: false,
                        variableWidth: true
                    }
                }
            ]
        });
    }

    if($('.blog-masonry').length > 0) {
        var exampleMinify = new MiniMasonry({
            container: '.blog-masonry',
            minify: true,
            gutterX: 30,
            gutterY: 30,
            surroundingGutter: false,
            baseWidth: 350,
        });
    }

    if($('.elements__fundraiservitals').length > 0) {
        let progressBar = $('.elements__fundraiservitals').find('.progress-bar-fill');
        let progress = progressBar.data('progress') + '%';
        progressBar.animate({
            width: progress
        }, 
        {
            duration: 1500,
            step: function(now, fx) {
                $('#progress-value').text(Math.round(now) + '%');
            }
        });
    }
    
    if (typeof recaptchascript == 'undefined') {
        Array.from(document.querySelectorAll("form")).forEach((form, index) => {
            let input = form.querySelector(`input[name="CaptchaToken"]`);
        
            if (input) {
                let captcha = document.createElement("div");
                captcha.className = "g-recaptcha";
                captcha.dataset.callback = "captchaCallback_" + index;
                captcha.dataset.sitekey = input.dataset.key;
        
                input.after(captcha);
        
                globalThis[`captchaCallback_${index}`] = token => input.value = token;
            }
        });
        
        let recaptchascript = document.createElement("script");
        recaptchascript.src = "https://www.google.com/recaptcha/api.js";
        document.body.appendChild(recaptchascript);
    }

    $('.userform input, .userform textarea').focus(function(ele){	
        $('label[for="' + $(this).attr('id') + '"]').addClass('active')
    });

    $('.userform input, .userform textarea').blur(function(ele){
        if($(this).val()==''){
            $('label[for="' + $(this).attr('id') + '"]').removeClass('active')
        }
    });

    $('.userform input, .userform textarea').change(function(ele){
        if($(this).val()==''){
            $('label[for="' + $(this).attr('id') + '"]').removeClass('filled')
        } else {
            $('label[for="' + $(this).attr('id') + '"]').addClass('filled')
        }
    });
        
}

$(function() {
    initScripts();

    document.addEventListener('turbolinks:load', function () {
        initScripts();
    });

});